<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <date-component label="Дата операції"
                            v-model="operation_date"
                            filled req
                            :class_="operation_date ? '' : 'req-star'"
                            required :rules="[v => !!v || 'Це поле є обов’язковим']"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-select type="text"
                          filled
                          :items="appliance_operation_type_select"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          label="Тип операції"
                          v-model="operation_type"
                          :class="operation_type ? '' : 'req-star'"
                          hide-details
                          color="grey"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  CREATE_APPLIANCE_OPERATION_JOURNAL,
  UPDATE_APPLIANCE_OPERATION_JOURNAL,
  DELETE_APPLIANCE_OPERATION_JOURNAL
} from "@/store/actions/appliance";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {appliance_operation_type_select} from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'flat_counter_operation_modal_delete'

export default {
  name: "HWP_Modal_ApplianceOperation",
  mixins: [ModalComponentMixin],
  data() {
    return {
      appliance_operation_type_select,
      operation_date: this.item.operation_date,
      operation_type: this.item.operation_type,
      appliance_id: this.item.appliance_id,
      flat_id: this.item.flat_id,
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.operation_date = this.item.operation_date
      this.operation_type = this.item.operation_type
      this.flat_id = this.item.flat_id
      this.appliance_id = this.item.appliance_id

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення операції по приладу',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Назва має бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        operation_date: this.operation_date,
        operation_type: this.operation_type,
        flat_id: this.flat_id,
        appliance_id: this.appliance_id
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_APPLIANCE_OPERATION_JOURNAL, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_APPLIANCE_OPERATION_JOURNAL, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.operation_date = payload.operation_date
              this.operation_type = payload.operation_type
              this.flat_id = payload.flat_id
              this.itemId = payload.id
              this.appliance_id = payload.appliance_id
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_APPLIANCE_OPERATION_JOURNAL, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
